/**
  * @tokens White
  * @presenter Color
  */
$white-100: #fff;
$white-200: #fafbff;
$white-300: #f1f3f9;
$white-400: #e8ebf1;

/**
  * @tokens LightGray
  * @presenter Color
  */

$light-gray-100: #dce0e7;
$light-gray-200: #ccd0da;
$light-gray-300: #b4b8c3;
$light-gray-400: #999da9;

/**
  * @tokens DarkGray
  * @presenter Color
  */

$dark-gray-100: #8c909b;
$dark-gray-200: #7c808b;
$dark-gray-300: #6d707b;
$dark-gray-400: #585a61;

/**
  * @tokens Black
  * @presenter Color
  */

$black-0: #000;
$black-100: #3d414b;
$black-200: #292d36;
$black-300: #161b26;
$black-400: #07090f;

/**
  * @tokens Purple
  * @presenter Color
  */

$purple-100: #e7e0ff;
$purple-200: #c2b1fb;
$purple-300: #7348f6;
$purple-400: #623cd8;
$purple-500: #361ca5;
$purple-600: #271880;

/**
  * @tokens Green
  * @presenter Color
  */

$green-100: #cfffc0;
$green-200: #99f07d;
$green-300: #6ac94c;
$green-400: #55c133;
$green-500: #22a717;
$green-600: #15800c;

/**
  * @tokens Yellow
  * @presenter Color
  */

$yellow-100: #f6f1ea;

/**
  * @tokens Other
  * @presenter Color
  */

$green: #1cc45b;
$red: #f83d3d;

/**
  * @tokens Gradients
  * @presenter Color
  */

$grad-purple-horizontal: linear-gradient(268deg, #361ca5 3.01%, #623cd8 100%);
$grad-purple-horizontal-inv: linear-gradient(268deg, #623cd8 3.01%, #361ca5 100%);
$grad-purple-vertical-inv: linear-gradient(355deg, #361ca5 3.06%, #623cd8 94.53%);
$grad-purple-vertical: linear-gradient(355deg, #623cd8 3.06%, #361ca5 94.53%);
$grad-green-horizontal: linear-gradient(90deg, #6ac94c 0%, #55c133 100%);
$grad-green-dark-horizontal: linear-gradient(90deg, #41a023 0%, #55c133 100%);
$grad-white-horizontal: linear-gradient(90deg, #f1f3f8 0%, #e8ebf1 100%);

/**
  * @tokens Primary
  * @presenter Color
  */

$color-main: $purple-500;
$color-secondary: $green-400;
$color-third: $black-300;

/**
  * @tokens States
  * @presenter Color
  */
$color-success: $green;
$color-danger: $red;

/**
  * @tokens TextColors
  * @presenter Color
  */

$text-color: $black-300;
$text-color--muted: $dark-gray-400;

/**
  * @tokens BorderColors
  * @presenter Color 
  */

$border-color: $white-400;
$border-color-main: $color-main;
$border-color-secondary: $color-secondary;
$border-color-third: $purple-100;

/**
  * @tokens PlaceholderColors
  * @presenter Color 
  */

$placeholder-color: linear-gradient(262deg, #351ba4 0%, #613bd8 100%), #472f92;
$placeholder-color-second: linear-gradient(91deg, #62bb46 0.07%, #6bcc4d 100%);
$placeholder-color-third: #e6e8ee;

/**
  * @tokens Common
  * @presenter Color  
  */

$body-bg: $white-200;

/**
  * @tokens Font Families
  * @presenter FontFamily 
  */

$font-family: var(--font-switzer, Switzer);
$font-family--headings: var(--font-mona-sans, 'Mona Sans');
$font-family--headings-narrow: var(--font-mona-sans-condensed, 'Mona Sans Condensed');
$font-family--headings-expanded: var(--font-mona-sans-expanded, 'Mona Sans Expanded');

/**
  * @tokens Font Sizes
  * @presenter FontSize 
  */

$font-size-3xs: 12px;
$font-size-mob-3xs: 13px;
$font-size-2xs: 14px;
$font-size-xs: 16px;
$font-size-s: 18px;
$font-size: 20px;
$font-size-mob-xs: 22px;
$font-size-l: 24px;
$font-size-mob-m: 25px;
$font-size-mob-l: 26px;
$font-size-xl: 28px;
$font-size-mob-xl: 30px;
$font-size-2xl: 32px;
$font-size-mob-1xl: 36px;
$font-size-mob-2xl: 38px;
$font-size-mob-3xl: 44px;
$font-size-3xl: 48px;
$font-size-4xl: 64px;
$font-size-5xl: 72px;
$font-size-6xl: 96px;

/**
  * @tokens Font Weights
  * @presenter FontWeight 
  */

$font-weight-s: 400;
$font-weight-m: 500;
$font-weight-l: 600;
$font-weight-xl: 700;

/**
  * @tokens Line Heights
  * @presenter LineHeight
  */

$line-height-2xs: 1.1;
$line-height-xs: 1.2;
$line-height-s: 1.3;
$line-height-m: 1.4;
$line-height-l: 1.5;
$line-height-xl: 1.6;
$line-height-2xl: 1.7;

/**
  * @tokens Opacity
  * @presenter Opacity
  */

$opacity: 0.7;
$opacity-half: 0.5;

/**
  * @tokens Easings
  * @presenter Easing
  */

$timing-function-ease: ease;
$timing-function-cubic: cubic-bezier(0.55, 0, 0.1, 1);

/**
  * @tokens Border Radius
  * @presenter BorderRadius
  */

$border-radius-2xs: 6px;
$border-radius-xs: 8px;
$border-radius-s: 10px;
$border-radius-m: 12px;
$border-radius-2m: 14px;
$border-radius-l: 16px;
$border-radius-2l: 18px;
$border-radius-xl: 20px;
$border-radius-2xl: 24px;
$border-radius-3xl: 42px;
$border-radius-rounded: 50%;

/**
  * @tokens Borders
  * @presenter Border
  */

$border: 2px solid #e8ebf1; /* #e8ebf1 — $border-color */
$border-main: 2px solid #361ca5; /* #361ca5 — $border-color-main */
$border-secondary: 2px solid #55c133; /* #55c133 — $border-color-secondary */
$border-third: 2px solid #e7e0ff; /* ##E7E0FF — $border-color-third */

// No in storybook tokens

/**
  * @tokens-end
  */

$breakpointSm: 480px;
$breakpointMd: 768px;
$breakpointLg: 1024px;
$breakpointXl: 1200px;
$breakpointXXl: 1440px;
$breakpointXXXl: 1920px;

$breakpoints: (
  '': '',
  'sm': $breakpointSm,
  'md': $breakpointMd,
  'lg': $breakpointLg,
  'xl': $breakpointXl,
  'xxl': $breakpointXXl,
  'xxxl': $breakpointXXXl,
);

$max-width: 1620px;
$page-gutter--xs: 15px;
$page-gutter: 20px;
$page-gutter--sm: 30px;
$page-gutter--md: 70px;

$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-gutter-width--sm: 20px;
$grid-gutter-width--md: 20px;
$grid-gutter-width--lg: 20px;
$grid-gutter-width--xl: 20px;

$transition-time: 0.15s $timing-function-ease;
$transition-time--slow: 0.3s $timing-function-ease;
$transition-time--v-slow: 0.5s $timing-function-ease;

$indents-step: 5;
$indents-count: 30;

$header-headroom-height: 76px;
$sticky-navigation-height: 65px;
$sticky-navigation-height-mobile: 55px;
$header-headroom-with-sticky-navigation: $header-headroom-height + $sticky-navigation-height;

$header-desktop-height: 120px;
$header-mob-height: 64px;
$header-mob-with-sticky-navigation-mob: $sticky-navigation-height-mobile + $header-mob-height;

$z-indexes: ('toaster', 'modal-overlay', 'header', 'other');
