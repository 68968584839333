@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.layout {
  $block-name: &;

  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  background-color: $body-bg;

  &__inner {
    display: flex;
    flex-direction: column;
    min-height: calc(var(--vh, 1vh) * 100);
    min-height: 100dvh;
  }

  &__content,
  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__main {
    @include mq($to: lg) {
    }
  }

  &__footer-wrapper {
    flex-shrink: 0;
  }
}
