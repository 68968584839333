@keyframes shine {
  to {
    background-position-x: -400%;
  }
}

@keyframes wrong-log {
  0% {
    left: 0;
  }

  20% {
    left: 20px;
  }

  40% {
    left: -20px;
  }

  60% {
    left: 20px;
  }

  80% {
    left: -20px;
  }

  100% {
    left: 0;
  }
}

@keyframes page-fade-in-up {
  0% {
    transform: translate3d(0, 7px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
