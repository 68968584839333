/**
 * Basic typography style for copy text
 */

body {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height-2xl;
  color: $text-color;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre {
  display: block;
  width: 100%;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(#000, 0.08);
  font-size: 0.7em;

  code {
    background: transparent;
  }
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}
