@at-root {
  @viewport {
    width: device-width;
  }
}

:root {
  --icon-white-color: #fff;
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: fade-out($color-secondary, 0.7);
  -ms-overflow-style: scrollbar;
  text-size-adjust: 100%;
  min-width: 375px;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  background-color: $body-bg;
  word-break: break-word;
}

[tabindex='-1']:focus {
  outline: none !important;
}

[role='button'] {
  cursor: pointer;
}

input[type='search'] {
  appearance: textfield;
}

// area,
// summary,
a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0;
}

button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

svg {
  fill: currentColor;

  a &,
  button & {
    pointer-events: none;
  }

  &:not(:root) {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  // color: $link-color;
  background-color: transparent;

  // transition: color $transition-time;

  //&:hover,
  //&:focus {
  //  color: $link-color--hover;
  //}

  // &:visited {
  //   color: $link-color--hover;
  // }

  &[href^='tel:'] {
    @include mq(xl) {
      // white-space: nowrap;
      // pointer-events: none;
      // text-decoration: inherit;
      // color: inherit;
    }
  }

  &[href^=''] {
    // pointer-events: none !important;
    // color: $text-color !important;
  }
}

::selection {
  background: fade-out($color-secondary, 0.1);
  color: $white-100;
}

::-moz-selection {
  background: fade-out($color-secondary, 0.1);
  color: $white-100;
}

button {
  appearance: none;
}

a,
button {
  outline: none;

  &:focus {
    outline: none;
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  border: 0;
  overflow: visible;
  border-top: 1px solid $border-color;
}

mark {
  background-color: $color-main;
}

.sbdocs .sbdocs-content {
  max-width: 1500px !important;

  .h-pt-0 {
    padding-top: 0;
  }

  .h-pb-0 {
    padding-bottom: 0;
  }
}

.t-zoomer__wrapper {
}

.page-transition {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-name: page-fade-in-up;

  // will-change: auto;
}

.docs-story {
  > div:first-child {
    @include reset-scrollbar;
  }
}
