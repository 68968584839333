@media print {
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline !important;
  }

  a[href]::after {
    content: ' [' attr(href) ']';
  }

  a[href^='#']::after,
  a[href^='javascript:']::after,
  a[href^='tel:']::after {
    content: '';
  }

  abbr[title]::after {
    content: ' [' attr(title) ']';
  }

  pre,
  blockquote {
    border: 1px solid #999 !important;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: #fff !important;
    }
  }
}
