.h-visible {
  @include visible;

  &--inline {
    @include visible(inline);
  }

  &--inline-block {
    @include visible(inline-block);
  }

  &--td {
    @include visible(table-cell);
  }
}

.h-hidden {
  @include hidden;
}

@each $name, $min-width in $breakpoints {
  @include mq($name) {
    .h-hidden--#{$name} {
      @include hidden;
    }

    .h-visible--#{$name} {
      @include visible;
    }

    .h-visible--#{$name}-inline {
      @include visible(inline);
    }

    .h-visible--#{$name}-inline-block {
      @include visible(inline-block);
    }

    .h-visible--#{$name}-td {
      @include visible(table-cell);
    }
  }
}
