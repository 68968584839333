/*
	Media Query mixin

	See https://github.com/sass-mq/sass-mq/ for original full version.

	@example scss
	 .element {
	   @include mq($from: mobile) {
		    color: red;
	   }

	   @include mq($to: tablet) {
		    color: blue;
	   }

	   @include mq(mobile, tablet) {
		    color: green;
	   }

	   @include mq($from: tablet, $and: '(orientation: landscape)') {
		    color: teal;
	   }

	   @include mq(em(950px)) {
		    color: hotpink;
	   }

	   @include mq(em(950px), $media-feature: height) {
		    color: hotpink;
	   }

	   @include mq(tablet, $media-type: screen) {
		    color: hotpink;
	   }

	   // Advanced use:
	   $custom-breakpoints: (L: 900px, XL: 1200px);

	   @include mq(L, $bp: $custom-breakpoints) {
		    color: hotpink;
	   }
	 }
*/

@mixin mq(
  $from: false,
  $to: false,
  $and: false,
  $media-feature: width,
  $media-type: all,
  $bp: $breakpoints
) {
  $min-width: 0;
  $max-width: 0;
  $media-query: '';

  // From: this breakpoint (inclusive)
  @if $from {
    @if type-of($from) == number {
      $min-width: $from;
    } @else {
      $min-width: map_get($bp, $from);
    }
  }

  // Until: that breakpoint (exclusive)
  @if $to {
    @if type-of($to) == number {
      $max-width: $to;
    } @else {
      $negative: 1px;
      $max-width: map_get($bp, $to) - $negative;
    }
  }

  @if $media-feature {
    @if $min-width != 0 {
      $media-query: '#{$media-query} and (min-#{$media-feature}: #{$min-width})';
    }
    @if $max-width != 0 {
      $media-query: '#{$media-query} and (max-#{$media-feature}: #{$max-width})';
    }
  }

  @if $and {
    $media-query: '#{$media-query} and #{$and}';
  }

  // Remove unnecessary media query prefix 'all and '

  @if $media-type == 'all' and $media-query != '' {
    $media-type: '';
    $media-query: str-slice(unquote($media-query), 6);
  }

  @media #{$media-type + $media-query} {
    @content;
  }
}

@mixin hardware($backface: true, $perspective: 1000) {
  @if $backface {
    backface-visibility: hidden;
  }

  perspective: $perspective;
}
