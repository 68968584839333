/*
	Typography
*/

// Font weights
.h-font-weight {
  &--normal {
    font-weight: 400;
  }

  &--bold {
    font-weight: 700;
  }
}

// Font styles
.h-font-style {
  &--italic {
    font-style: italic;
  }
}

// Text alignment
.h-text-align {
  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  @each $name, $min-width in $breakpoints {
    @include mq($name) {
      &--#{$name}-left {
        text-align: left;
      }

      &--#{$name}-center {
        text-align: center;
      }

      &--#{$name}-right {
        text-align: right;
      }
    }
  }
}

.h-lowercase {
  text-transform: lowercase;
}
